import React from 'react';


import Footer from '../components/footer';
import i18n from 'i18next';
import k from "./../i18n/keys";

import Icon from '../components/Icons';

//import samplePDF from "./../components/cookies.pdf";


export function FooterContainer() {

  return (

    <Footer>
        <Footer.Wrapper>
            <Footer.Row>

                <Footer.Column>
                    <Footer.Title>{i18n.t(k.CONTACT)}</Footer.Title>
                    <Footer.Link href="#/contact">{i18n.t(k.CONTACT_US1)}</Footer.Link>
                    <Footer.Link href="#"><small>{i18n.t(k.PHONE)}</small></Footer.Link>
                    <Footer.Link href="https://wa.me/5511990052502" target="_blank"><small>{"WHATSAPP: (11) 99005-2502"}</small></Footer.Link>
                    <Footer.Link href="#/formas" >{i18n.t(k.FORMAS_MENU)}</Footer.Link>
                    <Footer.Link href="#"><small>{"EMAIL: contato@boutiquedosuspiro.com"}</small></Footer.Link>
                </Footer.Column>

                <Footer.Column>
            <Footer.Title>{i18n.t(k.FOLLOW_US)}</Footer.Title>
                    <Footer.Link href="#"><Icon className="fab fa-facebook-f" />Facebook</Footer.Link>
                    <Footer.Link href="https://instagram.com/boutiquedosuspiro" target="_blank" rel="noopener noreferrer" ><Icon className="fab fa-instagram" />Instagram</Footer.Link>
                    <Footer.Link href="#"><Icon className="fab fa-youtube" />Youtube</Footer.Link>
                    <Footer.Link href="#"><Icon className="fab fa-twitter" />X-Twitter</Footer.Link>
                    <Footer.Link href="https://wa.me/5511990052502" target="_blank" rel="noopener noreferrer"><Icon className="fab fa-whatsapp" />Whatsapp</Footer.Link>
                </Footer.Column>

            </Footer.Row>
        <Footer.Copy >{i18n.t(k.COPYRIGHT)}</Footer.Copy>
        </Footer.Wrapper>

    </Footer>
  )
}
